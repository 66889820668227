/* ======================================================================== */
/* 21. fontObserver */
/* ======================================================================== */
function fontObserver() {

	return new Promise(function (resolve, reject) {

		var observers = [];

		if (!window.theme.fonts) {
			resolve(true);
		}

		$.each(window.theme.fonts, function () {

			var currentObserver = new FontFaceObserver(this);

			observers.push(currentObserver.load());

		});

		Promise.all(observers).then(function () {
			resolve(true);
		});

	});

}
